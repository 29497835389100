import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

export let stripePromise: Promise<Stripe | null>;

export const getStripe = (accountId?: string) => {
	if (!stripePromise) {
		stripePromise = loadStripe(
			process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string,
			accountId ? { stripeAccount: accountId } : {},
		);
	}

	return stripePromise;
};
