import { CaretRight, Plus } from "@withjuly/julycons/bold";
import { Button, Input, useToast } from "@withjuly/solisv2";
import { RouterOutput, trpc } from "../Utility/trpc";
import { useRef, useState } from "react";
import { useZodForm } from "~/utils/hooks/zod-form";
import { ManagerOnboardForm } from "@withjuly/fabric";
import { FormProvider } from "react-hook-form";
import { ZodInput } from "../Input/ZodInput";

type SessionUser = RouterOutput["user"]["current"];

interface ManagerOnboardingProps {
	user: SessionUser;
}

export const ManagerOnboarding: React.FC<ManagerOnboardingProps> = ({
	user,
}) => {
	const [isLoading, setIsLoading] = useState(() => false);

	const uploadRef = useRef<HTMLInputElement>(null);

	const setFirstNameAndLast = trpc.user.setFirstNameLastName.useMutation();
	const setProfilePhoto = trpc.user.setProfilePhoto.useMutation();
	const getUploadUrl = trpc.user.getProfilePictureUploadUrl.useMutation();
	const [profilePhotoUrl, setProfilePhotoUrl] = useState<string>();

	const { toast } = useToast();
	const utils = trpc.useContext();

	const form = useZodForm({
		schema: ManagerOnboardForm,
		values: {
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
		},
		submit: (data) => {
			setIsLoading(() => true);
			setFirstNameAndLast.mutate(data, {
				onSuccess: () => {
					utils.user.current.invalidate();
				},
				onError: () => {
					setIsLoading(() => false);
					toast({
						title: "Unable to update information",
						description: "Please contact July for support",
						variant: "error",
					});
				},
			});
		},
	});

	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];

		if (file) {
			if (file.size > 10_000_000) {
				toast({
					title: "That file is too large",
					description: "Please upload a file less than 10MB",
					variant: "error",
				});
				e.target.value = "";
				return;
			}

			const { url, fields, upload } = await getUploadUrl.mutateAsync({
				fileName: file.name,
				fileType: file.type,
				size: file.size,
			});

			const formData = new FormData();
			Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
			formData.append("Content-Type", file.type);
			formData.append("file", file);
			await fetch(url, {
				method: "POST",
				body: formData,
			});

			setProfilePhoto.mutate(
				{
					photoUuid: upload.uuid,
				},
				{
					onSuccess: () => {
						const localUrl = window.URL.createObjectURL(new Blob([file]));
						setProfilePhotoUrl(() => localUrl);
					},
				},
			);
		}
	};

	return (
		<div className="flex h-screen w-screen items-center justify-center">
			<FormProvider {...form}>
				<form
					className="flex w-full max-w-[480px] flex-col items-center gap-12"
					onSubmit={form.onSubmit}
				>
					<div className="flex w-full flex-col gap-8">
						<div className="flex flex-col items-center gap-4">
							<input
								type="file"
								className="hidden"
								ref={uploadRef}
								onChange={onUpload}
							/>
							<button
								className="bg-surface-hover-1 hover:bg-surface-hover-2 flex h-24 w-24 items-center justify-center overflow-clip rounded-full transition-colors"
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									uploadRef.current?.click();
								}}
							>
								{profilePhotoUrl ? (
									<img
										src={profilePhotoUrl}
										alt=""
										className="h-full w-full object-cover"
									/>
								) : (
									<Plus />
								)}
							</button>
							<p className="text-overline-xs text-text-secondary font-repro uppercase">
								profile photo (optional)
							</p>
						</div>

						<div className="flex w-full flex-col items-center gap-6">
							<Input
								label="Email address"
								placeholder="Email address"
								value={user.email ?? undefined}
								disabled={true}
							/>

							<div className="flex w-full items-start gap-3">
								<ZodInput
									label="First name"
									placeholder="First name"
									name="firstName"
								/>
								<ZodInput
									label="Last name"
									placeholder="Last name"
									name="lastName"
								/>
							</div>
						</div>
					</div>

					<Button
						type="submit"
						trailingIcon={CaretRight}
						className="w-full"
						isLoading={isLoading}
						disabled={isLoading}
					>
						Continue to July
					</Button>
				</form>
			</FormProvider>
		</div>
	);
};
