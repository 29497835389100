import { AgencyProfile, nonNull } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import {
	Bank,
	Briefcase,
	CaretRight,
	ChartLineUp,
	CurrencyCircleDollar,
	FileText,
	Gear,
	Presentation,
	User,
	UserGear,
	UsersFour,
	X,
} from "@withjuly/julycons/bold";
import { Kanban, Headset } from "@withjuly/julycons/fill";
import { Button, ScrollArea, Sidebar, Tag } from "@withjuly/solisv2";
import Link from "next/link";
import { useParams } from "next/navigation";
import { useRouter } from "next/router";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useMemo, useRef } from "react";
import Logo from "~/assets/july-wordmark-light.svg";
import { trpc } from "~/components/Utility/trpc";
import { AGENCY_PUBLIC_ID, CREATOR_PROFILE_UUID_KEY } from "~/utils/api";
import { useAgencyStore } from "~/utils/context/agency";
import { useAuth } from "~/utils/context/auth";
import { useDealStore } from "~/utils/context/deal";
import { useDeckStore } from "~/utils/context/deck";
import { useMediaKitStore } from "~/utils/context/mediakit";
import { useReportStore } from "~/utils/context/report";
import { useRosterStore } from "~/utils/context/roster";

export const AgencyMenu = () => {
	const utils = trpc.useContext();
	const router = useRouter();
	const params = useParams();
	const { data: agencyProfile } = trpc.agency.get.useQuery();
	const { user } = useAuth();
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const disablePaymentsBanner = trpc.agency.disablePaymentsBanner.useMutation();
	const isCrmEnabled = useFeatureFlagEnabled("agency-deals");

	const $resetAgencyState = useAgencyStore((state) => state.resetState);
	const $resetDealState = useDealStore((state) => state.resetState);
	const $resetDeckState = useDeckStore((state) => state.resetState);
	const $resetMediaKitsState = useMediaKitStore((state) => state.resetState);
	const $resetReportStae = useReportStore((state) => state.resetState);
	const $resetRosterState = useRosterStore((state) => state.resetState);

	const switchAgency = (agencyPublicId: string) => {
		localStorage.setItem(AGENCY_PUBLIC_ID, agencyPublicId);
		localStorage.removeItem(CREATOR_PROFILE_UUID_KEY);
		router.push(`/${agencyPublicId}`);
		utils.agency.invalidate();

		$resetAgencyState();
		$resetDealState();
		$resetDeckState();
		$resetMediaKitsState();
		$resetReportStae();
		$resetRosterState();
	};

	const agencyMembership = useMemo(() => {
		if (!user || !agencyProfile) {
			return undefined;
		}

		return user.agencies.find((agency) => {
			return agency.agency.uuid === agencyProfile.uuid;
		});
	}, [user, agencyProfile]);

	const section0Links = [
		{
			name: "Deals",
			onClick: () => router.push(`/${params.agency_id}/deals`),
			icon: <Kanban />,
			href: `/${params.agency_id}/deals`,
		},
	];

	const section1Links = [
		{
			name: "Talent",
			onClick: () => router.push(`/${params.agency_id}`),
			icon: <User />,
			href: `/${params.agency_id}`,
		},
	];

	const section2Links = [
		{
			name: "Roster",
			onClick: () => router.push(`/${params.agency_id}/roster`),
			icon: <UsersFour />,
			href: `/${params.agency_id}/roster`,
		},
		{
			name: "Decks",
			onClick: () => router.push(`/${params.agency_id}/one-sheets`),
			icon: <Presentation />,
			href: `/${params.agency_id}/one-sheets`,
		},
		{
			name: "Media Kits",
			onClick: () => router.push(`/${params.agency_id}/mediakits`),
			icon: <Briefcase />,
			href: `/${params.agency_id}/mediakits`,
		},
		{
			name: "Reports",
			onClick: () => router.push(`/${params.agency_id}/reports`),
			icon: <ChartLineUp />,
			href: `/${params.agency_id}/reports`,
			isBeta: true,
		},
	].filter(nonNull);

	const section3Links = [
		{
			name: "Invoices",
			onClick: () => router.push(`/${params.agency_id}/invoices`),
			icon: <FileText />,
			href: `/${params.agency_id}/invoices`,
			isHidden:
				!agencyProfile ||
				!agencyProfile.isPaymentsEnabled ||
				agencyProfile.paymentsApplicaionStatus !== "ACCEPTED",
		},
		{
			name: "Payments",
			onClick: () => router.push(`/${params.agency_id}/payments`),
			icon: <CurrencyCircleDollar />,
			href: `/${params.agency_id}/payments`,
		},
		{
			name: "Payouts",
			onClick: () => router.push(`/${params.agency_id}/payouts`),
			icon: <Bank />,
			href: `/${params.agency_id}/payouts`,
			isHidden:
				!agencyProfile ||
				!agencyProfile.isPaymentsEnabled ||
				agencyProfile.paymentsApplicaionStatus !== "ACCEPTED",
		},
	];

	return (
		<div className="relative flex h-full min-h-screen w-full flex-col">
			<Sidebar.Root
				className={cx(
					user && user.agencies && user.agencies.length > 1
						? "w-[328px] min-w-[328px] max-w-[328px]"
						: null,
				)}
			>
				<div className="flex h-full max-h-[100vh] w-full flex-row gap-0">
					{user && user.agencies && user.agencies.length > 1 ? (
						<div className="border-stroke-tertiary flex flex-col gap-6 border-r px-4 py-6">
							{user?.agencies.map((agency) => (
								<img
									key={agency.agency.publicId}
									className={cx(
										"bg-surface-tertiary h-8 min-h-8 w-8 min-w-8 cursor-pointer rounded-md outline outline-2 outline-offset-4 transition-all",
										params &&
											params.agency_id &&
											params.agency_id === agency.agency.publicId
											? "outline-sky-6 hover:outline-brand"
											: "outline-stroke-secondary hover:outline-stroke-primary",
									)}
									src={undefined} /* Logo will go here */
									alt="July"
									onClick={() => switchAgency(agency.agency.publicId)}
								/>
							))}
						</div>
					) : null}

					<div className="flex flex-1 flex-col">
						<Link className="mx-4 mb-4 mr-auto mt-8 px-4" href="/agency">
							<img className="h-6" src={Logo.src} alt="July" />
						</Link>

						<ScrollArea className="h-full flex-1 [&>*]:h-full">
							<Sidebar.Links>
								{isCrmEnabled ? (
									<Sidebar.Section>
										{section0Links.map((link, i) => {
											const Icon = () => link.icon ?? null;
											return (
												<Sidebar.Page
													icon={Icon}
													href={link.href}
													key={i}
													selected={router.asPath.startsWith(link.href)}
												>
													{link.name}
													<div className="ml-auto">
														<Tag color="purple" text="ALPHA" />
													</div>
												</Sidebar.Page>
											);
										})}
									</Sidebar.Section>
								) : null}
								<Sidebar.Section title="People">
									{section1Links.map((link, i) => {
										const Icon = () => link.icon ?? null;
										return (
											<Sidebar.Page
												icon={Icon}
												href={link.href}
												key={i}
												selected={router.asPath === `/${params.agency_id}`}
											>
												{link.name}
											</Sidebar.Page>
										);
									})}
								</Sidebar.Section>

								<Sidebar.Section title="Assets">
									{section2Links.map((link, i) => {
										const Icon = () => link.icon ?? null;
										return (
											<Sidebar.Page
												icon={Icon}
												href={link.href}
												key={i}
												selected={router.asPath.startsWith(link.href)}
											>
												<div className="flex w-full items-center justify-between">
													{link.name}
												</div>
											</Sidebar.Page>
										);
									})}
								</Sidebar.Section>

								<Sidebar.Section title="Payments">
									{section3Links
										.filter((link) => !link.isHidden)
										.map((link, i) => {
											const Icon = () => link.icon ?? null;

											return (
												<Sidebar.Page
													icon={Icon}
													href={link.href}
													key={i}
													selected={router.asPath.startsWith(link.href)}
												>
													<div className="flex w-full items-center justify-between">
														{link.name}
														{!agencyProfile ||
														!agencyProfile.isPaymentsEnabled ||
														agencyProfile.paymentsApplicaionStatus !==
															"ACCEPTED" ? (
															<div className="">
																<Tag color="green" text="NEW" />
															</div>
														) : null}
													</div>
												</Sidebar.Page>
											);
										})}
								</Sidebar.Section>

								<div className="flex flex-1 flex-col justify-end gap-4">
									{agencyProfile &&
									agencyMembership &&
									!agencyProfile?.isPaymentsEnabled &&
									!agencyMembership?.disabledPaymentsBanner ? (
										<div className="flex w-full flex-col justify-end pb-0">
											<div className="group/item">
												<div
													className="border-sky-alpha-5 relative flex flex-col gap-2 rounded-xl border px-2 py-3 opacity-90 transition-all group-hover/item:opacity-100"
													style={{
														background:
															"linear-gradient(90deg, rgba(24, 155, 216, 0.50) 0%, rgba(25, 129, 255, 0.11) 100%)",
													}}
												>
													<div
														className="absolute right-[14px] top-[14px] cursor-pointer "
														onClick={() => {
															if (agencyMembership) {
																disablePaymentsBanner.mutate(
																	agencyMembership?.uuid,
																);
															}
														}}
													>
														<X className="h-[12px] w-[12px] text-[#9DEEFF] opacity-0 transition-opacity duration-200 group-hover/item:opacity-100" />
													</div>
													<div className="flex flex-col gap-[6px] px-2">
														<p className="font-repro text-[13px] font-medium">
															July Payments is here!
														</p>
														<p className="text-sky-8 font-repro text-[13px] font-[350]">
															Send invoices, split payments and pay your talent
															faster.
														</p>
													</div>

													<Button
														className="w-[50%]"
														size="xs"
														variant="blank"
														onClick={() =>
															window.open(
																"https://calendly.com/wellswithjuly/july-payments-set-up?month=2024-12",
															)
														}
														trailingIcon={CaretRight}
													>
														Learn more
													</Button>
												</div>
											</div>
										</div>
									) : null}

									<div
										className={cx(
											agencyProfile &&
												agencyMembership &&
												!agencyProfile?.isPaymentsEnabled &&
												!agencyMembership?.disabledPaymentsBanner
												? "h-fit"
												: "h-full",
											"flex w-full flex-col justify-end",
										)}
									>
										<button
											className="group flex h-10 w-full cursor-pointer flex-row items-center gap-3 rounded-full px-4"
											onClick={() => {
												buttonRef.current?.click();
											}}
										>
											<Headset className="fill-text-tertiary group-hover:fill-text-secondary h-4 w-4 transition-all" />
											<p className="text-text-tertiary text-paragraph-sm group-hover:text-text-secondary transition-all">
												Support
											</p>
										</button>
									</div>
								</div>
							</Sidebar.Links>
						</ScrollArea>

						<Sidebar.Footer
							text={user ? `${user?.firstName} ${user?.lastName}` : undefined}
							subtext={agencyProfile?.name}
							logoUrl={agencyProfile?.logoUrl}
						>
							{agencyMembership?.role === "owner" ? (
								<Sidebar.FooterItem
									variant="link"
									href={`/${params.agency_id}/settings`}
									text="Agency Settings"
									icon={Gear}
								/>
							) : null}
							<Sidebar.FooterItem
								variant="link"
								href={`/${params.agency_id}/personal-settings`}
								text="Manager Settings"
								icon={UserGear}
							/>
						</Sidebar.Footer>
					</div>
				</div>
			</Sidebar.Root>

			<button className="launch_intercom hidden" ref={buttonRef}>
				Launch
			</button>
		</div>
	);
};
