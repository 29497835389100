import {
	Button,
	Checkbox,
	Input,
	Label,
	ScrollArea,
	Tag,
	useToast,
} from "@withjuly/solisv2";
import { useMemo, useState } from "react";
import { useAgency, useAgencyStore } from "~/utils/context/agency";
import { trpc } from "../Utility/trpc";
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	Elements,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardCvcElementOptions } from "@stripe/stripe-js";
import { getStripe } from "~/utils/stripe";
import posthog from "posthog-js";
import { cx, useWindowSize } from "@withjuly/frontend-common";

interface OnboardingProps {
	isOnboarding?: boolean;
}

export const Onboarding: React.FC<OnboardingProps> = ({
	isOnboarding = true,
}) => {
	const windowSize = useWindowSize();
	const isMobilePreview = useMemo(() => {
		if ((windowSize.width ?? 766) > 1228) {
			return false;
		} else {
			return true;
		}
	}, [windowSize]);

	return (
		<div className="bg-surface-primary flex h-full w-full flex-col items-center justify-center overflow-x-hidden p-8">
			<Elements stripe={getStripe()}>
				<ScrollArea
					className={cx(
						"h-full items-center justify-center",
						!isMobilePreview && "flex",
					)}
				>
					<PaymentMethodStep isOnboarding={isOnboarding} />
				</ScrollArea>
			</Elements>
		</div>
	);
};

interface PyamentMethodStepProps {
	isOnboarding?: boolean;
}

export const PaymentMethodStep: React.FC<PyamentMethodStepProps> = ({
	isOnboarding = true,
}) => {
	const { agencyProfile } = useAgency();

	const [name, setName] = useState(() => "");
	const [showCouponInput, setShowCouponInput] = useState(() => false);
	const [_coupon, setCoupon] = useState(() => "");
	const [isCardAddLoading, setIsCardAddLoading] = useState(() => false);
	const [noAuthSelected, setNoAuthSelected] = useState<boolean>(false);
	const [nonExclusiveSelected, setNonExclusiveSelected] =
		useState<boolean>(false);

	const createSession = trpc.agency.billing.createSession.useMutation();
	const updateAgency = trpc.agency.update.useMutation();

	const { toast } = useToast();
	const elements = useElements();
	const stripe = useStripe();

	const $setIsPayingCustomer = useAgencyStore(
		(store) => store.setIsPayingCustomer,
	);

	const price = useMemo(() => {
		if (noAuthSelected) {
			return 12;
		} else if (nonExclusiveSelected) {
			return 2;
		} else {
			return 6;
		}
	}, [noAuthSelected, nonExclusiveSelected]);

	const onSubmit = async () => {
		setIsCardAddLoading(() => true);
		const { clientSecret } = await createSession.mutateAsync();

		if (!stripe || !elements || !clientSecret) return;
		const card = elements?.getElement(CardNumberElement);
		if (!card) return;

		const { error } = await stripe.confirmCardSetup(clientSecret, {
			payment_method: {
				card,
				billing_details: {
					name: name,
				},
			},
		});

		if (error) {
			toast({
				title: "Failed to update payment method",
				description:
					"There was an issue updating your payment method. Please check your details and try again",
				variant: "error",
			});
			setIsCardAddLoading(() => false);
		} else if (agencyProfile) {
			updateAgency.mutate(
				{ ...agencyProfile, hasCompletedOnboarding: true },
				{
					onSuccess: () => {
						setIsCardAddLoading(() => false);
						$setIsPayingCustomer(true);
					},
				},
			);

			posthog.capture("Add Payment Method");
		}
	};

	const inputStyle: StripeCardCvcElementOptions = {
		style: {
			base: {
				fontFamily: "system-ui",
				fontSize: "16px",
				fontWeight: "350",
				color: "#FFFFFF",
				"::placeholder": {
					color: "#DDEFFD45",
				},
			},
		},
	};

	const windowSize = useWindowSize();
	const isMobilePreview = useMemo(() => {
		if ((windowSize.width ?? 766) > 1228) {
			return false;
		} else {
			return true;
		}
	}, [windowSize]);

	return (
		<div
			className={cx(
				"animate-slide-in-right font-repro flex h-full w-full select-none items-center justify-center gap-32 py-8",
				isMobilePreview && "flex-col gap-16 py-12",
			)}
		>
			<div className="flex w-[520px] min-w-[520px] flex-col gap-8">
				{isOnboarding ? (
					<p className="text-header-2xl font-repro">
						Start your 7-day free trial
					</p>
				) : null}

				<div className="flex w-full flex-col gap-6">
					<Input
						name="name"
						placeholder="Add a name"
						className="font-[system-ui] text-[14px]"
						label="Name on card"
						onChange={(e) => setName(() => e.target.value)}
					/>
					<div className="flex w-full flex-col gap-2">
						<div className="flex flex-col gap-2">
							<Label variant="overline" size="xs" color="secondary">
								Card information
							</Label>
							<div className="rounded-solis-md bg-slate-alpha-2 focus-within:border-brand flex h-10 w-full items-center justify-center px-3">
								<CardNumberElement options={inputStyle} className="w-full" />
							</div>
						</div>
						<div className="items-centner flex gap-2">
							<div className="rounded-solis-md bg-slate-alpha-2 focus-within:border-brand flex h-10 w-full items-center justify-center px-3">
								<CardExpiryElement options={inputStyle} className="w-full" />
							</div>
							<div className="rounded-solis-md bg-slate-alpha-2 focus-within:border-brand flex h-10 w-full items-center justify-center px-3">
								<CardCvcElement options={inputStyle} className="w-full" />
							</div>
						</div>
					</div>
					<div className="hidden flex-col gap-2">
						<Button
							className="self-start"
							variant="blank"
							size="xs"
							onClick={(e) => {
								e.preventDefault();
								setShowCouponInput((prev) => !prev);
							}}
						>
							Enter a discount code
						</Button>
						{showCouponInput ? (
							<div className="rounded-solis-md bg-slate-alpha-2 focus-within:border-brand flex h-10 w-full items-center justify-center px-3">
								<input
									className="font-inter w-full bg-transparent text-[14px] outline-0 placeholder:text-[#DDEFFD45] focus:border-0 focus:outline-0 focus:ring-0"
									placeholder="Add coupon"
									onChange={(e) => {
										setCoupon(() => e.target.value);
									}}
								/>
							</div>
						) : null}
					</div>
				</div>

				<div className="mt-4 flex w-full flex-col items-center gap-4">
					<Button
						className="w-full"
						disabled={isCardAddLoading}
						isLoading={isCardAddLoading}
						onClick={onSubmit}
						size="lg"
					>
						{isOnboarding ? "Start 7-day free trial" : "Add payment method"}
					</Button>
					{isOnboarding ? (
						<p className="text-paragraph-xs text-text-tertiary font-repro">
							By starting your trial, you agree to July&apos;s{" "}
							<span>
								<a
									className="underline"
									href="https://withjuly.com/legal/terms"
									target="_blank"
								>
									Terms of Service
								</a>
							</span>{" "}
							and{" "}
							<span>
								<a
									className="underline"
									href="https://withjuly.com/legal/privacy"
									target="_blank"
								>
									Privacy Policy
								</a>
							</span>
							.
						</p>
					) : null}
				</div>
			</div>

			<div
				className="border-stroke-secondary flex w-[520px] min-w-[520px] flex-col items-center rounded-2xl border"
				style={{
					background:
						"radial-gradient(100% 110.67% at 50% 0%, #41505A 0%, #2F3B43 45%, #202830 100%)",
				}}
			>
				<div className="py-3">
					<TabMenu />
				</div>
				<div className="bg-stroke-secondary h-px w-full" />
				<div className="flex w-full flex-col items-center gap-8 px-8 py-10">
					<p className="text-header-3xl font-repro">
						${price}
						<span
							className="text-text-secondary font-repro"
							style={{
								fontSize: "28px",
								fontWeight: "350",
								lineHeight: "140%",
							}}
						>
							/mo per talent
						</span>
					</p>

					<div className="flex w-full flex-col items-center gap-4">
						<Label variant="overline" size="2xs">
							Add ons
						</Label>
						<div className="border-stroke-secondary flex flex-col rounded-2xl border">
							<div
								className="flex cursor-pointer gap-4 p-3 pl-4"
								onClick={() => setNoAuthSelected(!noAuthSelected)}
							>
								<Checkbox
									size="sm"
									isChecked={noAuthSelected}
									onChecked={() => {
										// do nothing
									}}
								/>
								<div className="flex flex-col">
									<p className="text-button-xs font-repro ">No-Auth Stats</p>
									<p className="text-paragraph-xs font-repro text-text-secondary">
										Pull data from your talent&apos;s profiles without linking
										their accounts.
									</p>
								</div>
							</div>
							<div className="bg-stroke-secondary h-px w-full" />
							<div
								className="flex cursor-pointer gap-4 p-3 pl-4"
								onClick={() => setNonExclusiveSelected(!nonExclusiveSelected)}
							>
								<Checkbox
									size="sm"
									isChecked={nonExclusiveSelected}
									onChecked={() => {
										// do nothing
									}}
								/>
								<div className="flex flex-col">
									<p className="text-button-xs font-repro ">
										Non-Exclusive Talent
									</p>
									<p className="text-paragraph-xs font-repro text-text-secondary">
										Add talent to your roster who you don&apos;t exclusively
										represent.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex w-full items-center justify-between">
					<div className="bg-stroke-secondary h-px w-[224px]">
						{/** DIVIDER */}
					</div>
					<p
						className="text-text-secondary font-repro"
						style={{
							fontSize: "16px",
							fontWeight: "350",
							lineHeight: "140%",
						}}
					>
						Plus
					</p>
					<div className="bg-stroke-secondary h-px w-[224px]">
						{/** DIVIDER */}
					</div>
				</div>

				<p className="text-header-3xl font-repro mb-10 mt-8">
					$8
					<span
						className="text-text-secondary font-repro"
						style={{
							fontSize: "28px",
							fontWeight: "350",
							lineHeight: "140%",
						}}
					>
						/mo per manager
					</span>
				</p>

				<div className="border-t-stroke-secondary flex w-full justify-center border-t py-4">
					<p className="text-paragraph-xs font-repro text-text-secondary">
						Our minimum plan is{" "}
						<span className="text-button-xs font-repro text-text-primary">
							$50/month.{" "}
						</span>
						No refunds will be issued.
					</p>
				</div>
			</div>
		</div>
	);
};

const TabMenu = () => {
	return (
		<div className="bg-surface-primary flex items-center rounded-xl p-1 pr-2">
			<div className="bg-surface-hover-1 flex items-center justify-center rounded-lg px-4 py-1">
				<p className="text-button-xs font-repro">Quaterly</p>
			</div>
			<div className="flex cursor-not-allowed items-center">
				<p className="text-paragraph-xs text-text-tertiary font-repro ml-4 mr-3">
					Monthly
				</p>
				<Tag text="Coming soon" color="green" />
			</div>
		</div>
	);
};
